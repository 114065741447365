<template lang="pug">
.dropdown.dropdown-checkboxes
  button.btn.btn-default.btn-sm.dropdown-toggle(type="button" data-toggle="dropdown")
    | {{ selectedOrLabel }}
    |
    span.caret
  ul.dropdown-menu
    li(v-for="(val, optionName) in options" :key="val")
      label.radio-btn
        input(type="checkbox" :name="name" :value="val" v-model="checked")
        |
        | {{ optionName }}
    li(v-if="loading")
      loader
      br
    li.text-right
      button.btn.btn-xs.btn-default.clear.close-dropdown(type="button" v-on:click="clear()") Clear
</template>

<script>
  module.exports = {
    props: {
      label: {
        type: String,
        required: true
      },

      name: {
        type: String
      },

      // { label: value }
      options: {
        type: Object,
        default() { return {} }
      },

      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      },

      loading: {
        type: Boolean,
        default() { return false }
      }
    },

    data() {
      return {checked: []}
    },

    watch: {
      value() {
        this.checked = this.value
      },

      checked() {
        // For component v-model support
        this.$emit('input', this.checked)
      }
    },

    computed: {
      selectedOrLabel() {
        if (this.checked.length > 0) {
          return this.checkedLabels.join(', ')
        } else {
          return this.label
        }
      },

      checkedLabels() {
        const labels = []
        for (let label in this.options) {
          const val = this.options[label]
          if (this.checked.includes(val)) { labels.push(label) }
        }
        return labels;
      }
    },

    mounted() {
      this.checked = this.value
    },

    methods: {
      clear() {
        this.checked = []
      }
    }
  }
</script>

<style lang="scss" scoped>
// These styles are meant to mimic a Bootstrap button
.dropdown-checkboxes {
  display: inline-block;

  .btn {
    max-width: 200px;
    padding: 6px 12px;
    overflow: hidden;
    line-height: 20px;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
  }

  ul.dropdown-menu li {
    font-size: 80%;
    margin: 1px 10px;
  }

  ul.dropdown-menu label {
    font-weight: normal;
  }

  ul.dropdown-menu button {
    display: inline-block;
    margin: 6px 0 0 0;
    font-size: 90%
  }

  &.block {
    display: block;

    >.btn {
      display: block;
      width: 100%;
      max-width: none;
      padding-right: 20px;
    }

    .caret {
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }
}

</style>
