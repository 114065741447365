<template lang="pug">
CheckboxIcon(:checked="value" @click.native="toggle")
</template>

<script>
  import CheckboxIcon from './checkbox_icon.vue'

  export default {
    components: {
      CheckboxIcon
    },

    props: {
      value: {
        type: Boolean,
        required: false // required is false to allow null values
      }
    },

    methods: {
      toggle() {
        this.$emit('input', !this.value);
      }
    }
  }
</script>
