<template lang="pug">
dropdown-checkboxes(
  :label="label"
  :name="name"
  :options="userOptions"
  :value="value"
  @input="update"
  :loading="loading"
)
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default() { return 'Owner' }
      },
      name: {
        type: String,
        default() { return 'q[user_id_in][]' }
      },
      value: {
        type: Array,
        default() { return [] }
      }
    },

    computed: {
      userOptions() {
        return this.$store.getters['users/allAsObject'];
      },

      loading() {
        return this.$store.getters['users/all'].length === 0;
      }
    },

    mounted() {
      this.$store.dispatch('users/fetchOnce');
    },

    methods: {
      update(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
</script>
