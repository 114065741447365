import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  page: {
    id: null,
    issue: {},
    title: '',
    slug: '',
    notes: ''
  }
}

// getters
const getters = make.getters(state)

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  ...make.actions(state),

  fetch({ commit, dispatch }, { pageId }) {
    axios
      .get(`/api/internal/pages/${pageId}`)
      .then(response => {
        dispatch('setPage', response.data.page)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      });
  },

  update({ state: { page }, commit, dispatch }) {
    axios
      .patch(
        `/api/internal/pages/${page.id}`,
        { page }
      )
      .then(({ data }) => {
        commit('SET_PAGE', data.page)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
