<template lang="pug">
textarea.form-control(
  :value="value"
  :placeholder="placeholder"
  @change="change"
  @blur="blur"
)
</template>

<script>
  export default {
    props: {
      value: {
        type: String
      },
      placeholder: {
        type: String,
        required: false
      },
      stopChangePropagation: {
        type: Boolean,
        default: () => false
      }
    },

    methods: {
      change(event) {
        if(this.stopChangePropagation) event.stopPropagation()

        this.$emit('input', event.target.value);
      },

      blur(event) {
        this.$emit('blur', event.target.value);
      }
    }
  }
</script>
