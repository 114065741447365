import axios from 'axios'
import { make } from 'vuex-pathify'

import { getDefaultPaginationState } from '../shared/pagination'

// initial state
const state = {
  ...getDefaultPaginationState(),
  issues: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  forPublication: ({ issues }) => ({ id }) => {
    return issues.filter(issue => issue.publication.id == id)
  },

  forId: ({ issues }) => (id) => {
    return issues.find(issue => issue.id === id);
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  SET_PAGE_CURRENT(state, page) {
    state.page.current = page
  },

  SET_CURRENT({ issues }, { id, current }) {
    const issue = issues.find(iss => iss.id === id)

    // If we're setting a new current issue for the publication,
    // we need to set the old current publication issue to no longer be current.
    if (current) {
      const oldCurrent = issues.find(iss => iss.publication.id === issue.publication.id && iss.current)
      if (oldCurrent) oldCurrent.current = false
    }

    issue.current = current
  },

  REMOVE({ issues }, issueId) {
    const i = issues.map(issue => issue.id).indexOf(issueId)
    issues.splice(i, 1)
  },
}

// actions
const actions = {
  ...make.actions(state),

  fetch({ commit, dispatch, getters }, options = {}) {
    commit('SET_FETCH_INITIATED', true)
    if (options?.page) commit('SET_PAGE_CURRENT', options.page)
    const page = getters['page'].current

    return axios
      .get('/api/internal/issues', { params: { page } })
      .then(({ data: { meta, issues } }) => {
        dispatch('setPage', meta.page)
        dispatch('setIssues', issues)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      });
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return Promise.resolve();

    return dispatch('fetch')
  },

  async toggleCurrent({ commit, dispatch }, { id, current }) {
    return axios
      .patch(
        `/api/internal/issues/${id}`,
        { current }
      )
      .then(() => {
        commit('SET_CURRENT', { id, current })
        dispatch('messages/addNotice', 'Current issue set', { root: true })
      })
      .catch(err => {
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  destroy({ dispatch, commit }, { id }) {
    return axios.delete(`/api/internal/issues/${id}`).then(() => {
      dispatch('messages/addNotice', 'Issue deleted', { root: true })
      commit('REMOVE', id)
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
