import axios from 'axios'
import { make } from 'vuex-pathify'

// helpers
const getDefaultState = () => {
  return {
    issue: {
      id: null,
      publication: {},
      title: '',
      slug: '',
      publish_on: null,
      notes: ''
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  // Convert the nested publication.id to a publication_id
  forApi({ issue }) {
    const { publication, ...others } = issue

    return { ...others, publication_id: publication.id }
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, { ...getDefaultState() })
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetch({ dispatch }, { issueId }) {
    return axios
      .get(`/api/internal/issues/${issueId}`)
      .then(response => {
        dispatch('setIssue', response.data.issue)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  save({ state: { issue }, dispatch}) {
    if(issue.id) {
      return dispatch('update')
    } else {
      return dispatch('create')
    }
  },

  create({ getters, commit, dispatch }) {
    const issue = getters['forApi']

    return axios
      .post('/api/internal/issues/', { issue })
      .then(({ data }) => {
        commit('SET_ISSUE', data.issue)
        dispatch('messages/addNotice', 'Issue created', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  update({ getters, commit, dispatch }) {
    const issue = getters['forApi']

    return axios
      .patch(
        `/api/internal/issues/${issue.id}`,
        { issue }
      )
      .then(({ data }) => {
        commit('SET_ISSUE', data.issue)
        dispatch('messages/addNotice', 'Issue saved', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
