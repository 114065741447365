import axios from 'axios'
import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    storyIds: [],
    when: 'dont',
    pubTime: new Date()
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(state),

  submit({ state, commit, dispatch }) {

    // Accommodate for a bug where the state isn't correctly being set as the modal loads
    if( state.when == 'dont' ){
      state.pubTime = null;
    }

    return axios.patch('/api/internal/communityq_stories/batch_update', {
      community_q_stories: {
        ids: state.storyIds,
        web_publish_on: state.pubTime
      }
    }).then(({ data }) => {
      dispatch('messages/addNotice', data.message, { root: true })
      commit('RESET')
      return data
    }).catch(err => {
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
