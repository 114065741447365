import Vue from 'vue'
import axios from 'axios'
import { make } from 'vuex-pathify'

// helpers
function newStoryPublication(publicationId) {
  return Object.assign({}, {
    id: null,
    story_id: null,
    publication_id: publicationId,
    content_type_key: null,
    story_template_id: null,
    exported_at: null,
    subsite_town_ids: []
  })
}

const getDefaultState = () => {
  return {
    storyPublications: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  byPublicationId: ({ storyPublications }) => (publicationId) => {
    return Object.values(storyPublications).find(
      storyPub => storyPub.publication_id === publicationId
    ) || newStoryPublication(publicationId)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  SET_STORY_PUBLICATION({ storyPublications }, { story_publication }) {
    Vue.set(storyPublications, story_publication.id, story_publication)
  },

  REMOVE_STORY_PUBLICATION({ storyPublications }, story_publication_id) {
    Vue.delete(storyPublications, story_publication_id)
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
};

// actions
const actions = {
  ...make.actions(state),

  async fetch({ dispatch }, { storyId }) {
    return axios
      .get(`/api/internal/stories/${storyId}/story_publications`)
      .then(response => {
        dispatch('setStoryPublications', response.data.story_publications);
      })
      .catch(err => {
        console.error(err);
        dispatch('messages/addError', err, { root: true });
      });
  },

  async create({ commit, dispatch }, { story_publication }) {
    const { story_id } = story_publication

    return axios
      .post(`/api/internal/stories/${story_id}/story_publications/`, { story_publication })
      .then(({ data: { story_publication } }) => {
        // TODO: this should set the one speficic story_publication in the array
        commit('SET_STORY_PUBLICATION', { story_publication })
        dispatch('messages/addNotice', 'Section status created', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async update({ commit, dispatch }, { story_publication }) {
    const { story_id } = story_publication

    return axios
      .patch(
        `/api/internal/stories/${story_id}/story_publications/${story_publication.id}`,
        { story_publication }
      )
      .then(({ data: { story_publication } }) => {
        commit('SET_STORY_PUBLICATION', { story_publication })
        dispatch('messages/addNotice', 'Publication saved', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async updateAll({ state: { storyPublications }, dispatch }) {
    Object.values(storyPublications).forEach(story_publication => {
      dispatch('update', { story_publication })
    })
  },

  async destroy({ commit, dispatch }, { story_publication: { id, story_id } }) {
    return axios.delete(`/api/internal/stories/${story_id}/story_publications/${id}`).then(() => {
      commit('REMOVE_STORY_PUBLICATION', id)
      dispatch('messages/addNotice', 'Publication removed', { root: true })
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
