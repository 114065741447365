import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminSqueueIndex from './pages/admin/squeues/index/index.vue'
import AdminSqueueNew from './pages/admin/squeues/new/index.vue'
import AdminSqueueEdit from './pages/admin/squeues/edit/index.vue'
import AdminStyleMapIndex from './pages/admin/style_maps/index.vue'
import AdminStyleMapEdit from './pages/admin/style_maps/edit.vue'
import IssuesIndex from './pages/issues/index/index.vue'
import IssueShow from './pages/issues/show/index.vue'
import IssueNew from './pages/issues/new/index.vue'
import IssueEdit from './pages/issues/edit/index.vue'
import MediaIndex from './pages/media/index/index.vue'
import PageShow from './pages/pages/show/index.vue'
import StoriesIndex from './pages/stories/index/index.vue'
import StoryForm from './components/stories/form/story_form.vue'
import StoryShow from './pages/stories/show/index.vue'
import UserEdit from './pages/user/edit/index.vue'

const routes = [
  {
    path: '/admin/squeues',
    name: 'adminSqueues',
    component: AdminSqueueIndex
  },
  {
    path: '/admin/squeues/new',
    name: 'adminSqueues',
    component: AdminSqueueNew
  },
  {
    path: '/admin/squeues/:squeueId/edit',
    name: 'adminSqueues',
    component: AdminSqueueEdit,
    props: ({ params: { squeueId } }) => ({ squeueId: Number(squeueId) })
  },
  {
    path: '/admin/style_maps',
    name: 'adminStyleMaps',
    component: AdminStyleMapIndex
  },
  {
    path: '/admin/style_maps/:styleMapId/edit',
    name: 'editAdminStyleMap',
    component: AdminStyleMapEdit,
    props: ({ params: { styleMapId } }) => ({ styleMapId: Number(styleMapId) })
  },
  {
    path: '/issues/new',
    name: 'newIssue',
    component: IssueNew
  },
  {
    path: '/issues/:issueId/edit',
    name: 'editIssue',
    component: IssueEdit,
    props: ({ params: { issueId } }) => ({ issueId: Number(issueId) })
  },
  {
    path: '/issues/:issueId',
    name: 'issue',
    component: IssueShow,
    props: ({ params: { issueId } }) => ({ issueId: Number(issueId) })
  },
  {
    path: '/issues/:issueId/pages/:pageId',
    name: 'issuePage',
    component: PageShow,
    props: ({ params: { issueId, pageId } }) => ({
      issueId: Number(issueId),
      pageId: Number(pageId)
    })
  },
  {
    path: '/issues',
    name: 'issues',
    component: IssuesIndex
  },
  {
    path: '/issues/:issueId/stories/:storyId/edit',
    name: 'editIssueStory',
    component: StoryForm,
    props: ({ params: { issueId, storyId } }) => ({
      issueId: Number(issueId),
      storyId: Number(storyId)
    })
  },
  {
    path: '/issues/:issueId/stories/:storyId',
    name: 'issueStory',
    component: StoryShow,
    props: ({ params: { issueId, storyId } }) => ({
      issueId: Number(issueId),
      storyId: Number(storyId)
    })
  },
  {
    path: '/issues/:issueId/pages/:pageId/stories/:storyId/edit',
    name: 'editPageStory',
    component: StoryForm,
    props: ({ params: { issueId, pageId, storyId } }) => ({
      issueId: Number(issueId),
      pageId: Number(pageId),
      storyId: Number(storyId)
    })
  },
  {
    path: '/issues/:issueId/pages/:pageId/stories/:storyId',
    name: 'pageStory',
    component: StoryShow,
    props: ({ params: { issueId, storyId } }) => ({
      issueId: Number(issueId),
      storyId: Number(storyId)
    })
  },
  {
    path: '/media',
    name: 'media',
    component: MediaIndex
  },
  {
    path: '/stories',
    name: 'stories',
    component: StoriesIndex
  },
  {
    path: '/stories/:storyId',
    name: 'story',
    component: StoryShow,
    props: ({ params: { storyId } }) => ({ storyId: Number(storyId) })
  },
  {
    path: '/stories/:storyId/edit',
    name: 'editStory',
    component: StoryForm,
    props: ({ params: { storyId } }) => ({ storyId: Number(storyId) })
  },
  {
    path: '/user/edit',
    name: 'editUser',
    component: UserEdit
  },
  {
    path: '/',
    component: StoriesIndex
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      // NOTE: y: works currently, but it newer versions of Vue Roter it's top:.
      // So I'm providing both to future proof it, but y: can be safely removed eventually.
      return { y: 0, top: 0 }
    }
  }
})

Vue.use(VueRouter)

export default  router
