<template lang="pug">
.form-group
  Label(:text="label")
  SelectInput(
    v-model="publicationId"
    :options="options"
    :blank-option-name="blankOptionName"
  )
</template>

<script>
  import { isEmpty } from 'lodash'

  import Label from './label.vue'
  import SelectInput from './select_input.vue'

  export default {
    components: {
      Label,
      SelectInput
    },

    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      },

      label: {
        type: String,
        default() { return 'Publication' }
      },

      required: {
        type: Boolean,
        default() { return false }
      }
    },

    data() {
      return {
        publicationId: null
      }
    },

    computed: {
      options() {
        const opts = this.$store.getters['publications/allAsObject']

        // Set the default value if needed
        if (this.required && !isEmpty(opts)) {
          const id = Object.values(opts)[0]
          // TODO: figure out why this updating isn't changing the UI
          this.publicationId = id
        }

        return opts
      },

      blankOptionName() {
        return this.required ? false : 'None'
      }
    },

    watch: {
      value() {
        this.publicationId = this.value
      },

      publicationId() {
        // For component v-model support
        this.$emit('input', this.publicationId);
      }
    },

    async mounted() {
      await this.$store.dispatch('publications/fetchOnce')
      this.publicationId = this.value
    }
  }
</script>
