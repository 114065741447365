import Vue from 'vue'

Vue.filter('filePath', function(value) {
  if (value != null) {
    const parts = String(value).split('/');
    parts.pop();
    return `${parts.join('/')}/`;
  }
});

Vue.filter('fileName', function(value) {
  if (value != null) {
    return String(value).split('/').pop();
  }
});

Vue.filter('fileExtension', function(value) {
  if (value != null) {
    return String(value).split('.').pop();
  }
});
