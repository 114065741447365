<template lang="pug">
i.fa(:class="{ 'fa-check-square-o': checked, 'fa-square-o': !checked }")
</template>

<script>
  export default {
    props: {
      checked: {
        type: Boolean,
        required: false // required is false to allow null values
      }
    }
  }
</script>
