<template lang="pug">
div
  h1 Saved Searches

  template(v-if="storySavedSearches.length == 0")
    .alert.alert-info There aren't any saved searches yet
  template(v-else)
    story-saved-search-table

  hr

  p.text-center.text-muted
    a(href="/stories") Search for stories
    |
    | and click the
    |
    span.btn.btn-info.btn-xs
      i.fa.fa-search
      |
      | Search
      |
      i.fa.fa-caret-down
    |
    | dropdown button to save new searches
</template>

<script>
  import { sync } from 'vuex-pathify';

  export default {
    computed: {
      storySavedSearches: sync('storySavedSearches/storySavedSearches'),
    },

    created() {
      this.$store.dispatch('storySavedSearches/fetch');
    }
  }
</script>
