import axios from 'axios'
import { unionBy, remove } from 'lodash'
import { make } from 'vuex-pathify'

// helpers
const getBlankPage = () => {
  return {
    id: null,
    issue: { id: null, title: '' },
    title: '',
    slug: '',
    notes: '',
    story_count: 0,
    list_order: 1,
    _destroy: false
  }
}

// initial state
const state = {
  edit: false,
  pages: [],
  fetchInitiatedIssues: []
}

// getters
const getters = {
  ...make.getters(state),

  forIssue: ({ pages }) => ({ id }) => {
    return pages.filter(page => page.issue.id === id)
  },

  forId: ({ pages }) => (id) => {
    return pages.find(page => page.id === id);
  },

  fetchInitiatedFor: ({ fetchInitiatedIssues }) => ({ issueId }) => {
    return fetchInitiatedIssues.includes(Number(issueId))
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_ISSUE_TO_FETCH_INITIATED({ fetchInitiatedIssues }, { issueId }) {
    const id = Number(issueId)

    // Avoid duplicates
    if(fetchInitiatedIssues.includes(id)) return

    fetchInitiatedIssues.push(id)
  },

  ADD({ pages }, page) {
    pages.push(
      { ...getBlankPage(), ...page }
    )
  },

  REMOVE_ISSUE({ pages }, { issueId }) {
    remove(pages, (page) => page.issue.id === issueId)
  }
}

// actions
const actions = {
  ...make.actions(state),

  mergePages({ state, dispatch }, pages) {
    dispatch(
      'setPages',
      unionBy(pages.concat(state.pages), 'id')
    )
  },

  setPages({ state }, pages) {
    state.pages = pages.map(page => {
      // _destroy isn't a DB attribute but it needs to be sent over HTTP
      // to trigger a destroy
      return Object.assign({}, page, {
        _destroy: false
      })
    })
  },

  async fetch({ commit, dispatch }, { issueId }) {
    commit('ADD_ISSUE_TO_FETCH_INITIATED', { issueId })

    return axios
      .get(`/api/internal/issues/${issueId}/pages`)
      .then(response => {
        dispatch("mergePages", response.data.pages)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      });
  },

  async fetchOnce({ dispatch, getters }, { issueId }) {
    if(getters['fetchInitiatedFor']({ issueId })) return Promise.resolve()

    return dispatch('fetch', { issueId })
  },

  save({ getters, commit, dispatch }, { issueId }) {
    const issuePages = getters['forIssue']({ id: Number(issueId) })

    return new Promise((resolve, reject) => {
      axios.patch(`/api/internal/issues/${issueId}`, {
        issue: { pages_attributes: issuePages }
      })
      .then((response) => {
        // Clear out all of the objects for the issue before we merge in the
        // new ones. Because the current ones have null ids which means ids
        // won't match and we'll get duplicates.
        commit('REMOVE_ISSUE', { issueId })
        dispatch('mergePages', response.data.issue.pages)
        resolve(response)
      }).catch((data) => {
        reject(data)
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
