<template lang="pug">
.btn-group(v-if="!currentUser.role_is_limited")
  button.btn.btn-default.dropdown-toggle(:class="buttonClasses" type="button" data-toggle="dropdown")
    i.fa.fa-caret-down
  ul.dropdown-menu.pull-right
    li(v-if="show('duplicate')")
      a(@click.prevent="cloneStory()") Duplicate
    li(v-if="show('delete')")
      a(@click.prevent="deleteStory()" :class="{ locked: locked }") Delete
    li.divider(v-if="show('divider')")
    li.dropdown-header Export to...
    li(v-for="exportFormat in exportFormats")
      a(@click="routeTo({ path: `export_formats/${exportFormat.id}` })" target="new")
        | {{ exportFormat.name }}
    li
      a(@click="routeTo({ extension: 'pdf' })") PDF
    li
      a(@click="routeTo({ extension: 'zip' })") Zip
    li
      a.email-export(@click="exportViaEmail()")
        | Email
    li
      a.communityq-export(@click="publishToCommunityQ()" :class="{ locked: locked }")
        | Web
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import { storyRoute } from '../../../utils/route_helpers.js'

  export default {
    props: {
      storyId: {
        type: Number,
        required: true
      },

      storyName: {
        type: String,
        required: false
      },

      buttonClasses: {
        type: Array,
        default: () => []
      },

      without: {
        type: Array,
        default: () => []
      },

      locked: {
        type: Boolean,
        default: () => false
      }
    },

    computed: {
      currentUser: sync('currentUser/currentUser'),
      ...mapGetters('exportFormats', {
        exportFormats: 'forStories'
      }),
      ...mapGetters('story', [
        'isDirty'
      ]),
    },

    mounted() {
      this.$store.dispatch('currentUser/fetchOnce')
      this.$store.dispatch('exportFormats/fetchOnce')
    },

    methods: {
      checkDirty() {
        return new Promise((resolve, _reject) => {
          if(this.isDirty) {
            // This feels a bit hacky but there is some very component-specific, and fairly
            // comlpex logic in the StoryForm that would be hard to reproduce outside of it.
            // Still there may be a better way.
            if(this.$parent.save) {
              this.$parent.save().then(() => { resolve(true) })
            } else {
              resolve(true)
            }
          } else {
            resolve(true)
          }
        })
      },

      routeTo({ path, extension } = {}) {
        this.$store.dispatch('messages/addNotice', 'Exporting...')

        this.checkDirty().then(() => {
          const url = (
            this.addExtension(this.addPath(`/stories/${this.storyId}`, path), extension)
          )

          window.location = url
        })
      },

      markPublished() {
        if(!confirm(`Tag ${this.storyName} as published?`)) return

        this.$store.dispatch('stories/markPublished', { id: this.storyId }).then(() => {
          this.$emit('should-refresh')
        })
      },

      cloneStory() {
        this.checkDirty().then(() => {
          this.$store.dispatch('stories/clone', { id: this.storyId }).then(({ story }) => {
            this.$router.push(storyRoute({
              storyId: story.id,
              storyRoute: 'editStory',
              issueRoute: 'editIssueStory',
              pageRoute: 'editPageStory',
              router: this.$router
            }))

            this.$store.dispatch('messages/addNotice', 'Story cloned')
          })
        })
      },

      deleteStory() {
        if (this.locked) return

        if(!confirm(`Sure you want to delete ${this.storyName}?`)) return;

        this.$store.dispatch('stories/destroy', { id: this.storyId }).then(() => {
          this.$emit('should-refresh');
        });
      },

      exportViaEmail() {
        this.checkDirty().then(() => {
          this.$store.commit('emailExporting/SET_STORY_ID', this.storyId)
        })
      },

      publishToCommunityQ() {
        if (this.locked) return

        this.checkDirty().then(() => {
          this.$store.commit('communityqPublishing/SET_STORY_IDS', [this.storyId])
        })
      },

      addPath(url, path) {
        if(!path) return url;

        return `${url}/${path}`;
      },

      addExtension(url, extension) {
        if(!extension) return url;

        return `${url}.${extension}`
      },

      show(linkName) {
        return !this.without.includes(linkName)
      }
    }
  }
</script>

<style lang="scss" scoped>
  /*
    Links without an href have a text select pointer by default,
    but we want them to act just like links.
  */
  a {
    cursor: pointer;

    &.locked {
      color: #ccc;
      cursor: not-allowed;

      &:hover {
        color: #ccc;
        background-color: transparent;
      }
    }
  }
</style>
