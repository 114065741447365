import Vue from 'vue'
import { eventHub } from '../../utils/event_hub'

Vue.component('ap-batch-routing-form', {
  template: '#ap-batch-routing-form-component-template',

  data() {
    return {routeTo: null};
  },

  created() {
    eventHub.$on('toggle-ap-batch-routing-select-all', this.toggleSelectAll);
  },

  beforeDestroy() {
    eventHub.$off('toggle-ap-batch-routing-select-all', this.toggleSelectAll);
  },

  methods: {
    toggleSelectAll(value) {
      const inputs = document.querySelectorAll('input.ap-story[type=checkbox]');
      const result = [];

      for (let i in inputs) {
        const input = inputs[i];

        if (input.type === 'checkbox') { result.push(input.checked = value.on); } else {
          result.push(undefined);
        }
      }
    }
  }
}
);
