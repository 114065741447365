import qs from 'qs';
import { toString, mapValues, isEmpty } from 'lodash';
import { make } from 'vuex-pathify';
import TabStorage from '../../utils/tab_storage.js';
import {
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  sortStringForSearch
} from '../shared/sorting.js';

const TAB_STORAGE_KEY = 'last-story-index-search';
const DEFAULT_SEARCH = {
  text_cont: null,
  user_id_in: [],
  story_sections_section_id_in: [],
  story_publications_publication_id_in: [],
  squeue_id_in: [],
  slug_cont: null,
  byline_cont: null,
  content_cont: null,
  pub_date_gteq: null,
  pub_date_lteq: null,
  pub_date_within: null,
  custom_folders_path_eq: null,
  sort_column: DEFAULT_SORT_COLUMN,
  sort_direction: DEFAULT_SORT_DIRECTION
};

// initial state
const state = {
  search: Object.assign({}, DEFAULT_SEARCH)
};

// getters
const getters = {
  ...make.getters(state),

  sort({ search }) {
    const col = search.sort_column || DEFAULT_SORT_COLUMN;
    const dir = search.sort_direction || DEFAULT_SORT_DIRECTION;

    return `${col} ${dir}`;
  },

  // You can't sort by search score (relevance) if there is no search text.
  relevanceSortAvailable({ search }) {
    return !isEmpty(search?.text_cont);
  },

  anyQueries({ search }, getters) {
    if (getters.anyAdvancedQueries) return true;

    return !!(
      search.text_cont ||
      search.user_id_in.length > 0 ||
      search.story_sections_section_id_in.length > 0 ||
      search.story_publications_publication_id_in.length > 0 ||
      search.squeue_id_in.length > 0 ||
      search.sort_column !== DEFAULT_SEARCH.sort_column ||
      search.sort_direction !== DEFAULT_SEARCH.sort_direction
    );
  },

  anyAdvancedQueries({ search }) {
    return (
      search.slug_cont ||
      search.byline_cont ||
      search.content_cont ||
      search.pub_date_gteq ||
      search.pub_date_lteq ||
      search.pub_date_within ||
      search.custom_folders_path_eq
    );
  },

  // Converts falsey values to null
  normalized({ search }) {
    return mapValues(search, val => val ? val : null)
  },

  // Returns the search state without anything that is the same as the default
  withDefaultsExcluded(_, { normalized }) {
    return Object.keys(normalized).reduce((diff, key) => {
      if (normalized[key] === DEFAULT_SEARCH[key]) return diff;

      return {
        ...diff,
        [key]: normalized[key]
      }
    }, {})
  },

  // This converts the object into a format that fits the query string we'll be using.
  // Which happens to be Ransack at the moment. And it expects a query string like `s=column+direction`.
  forQueryParams(_, { normalized, withDefaultsExcluded }) {
    const { sort_column, sort_direction, ...withoutSort} = withDefaultsExcluded;

    const sortVal = sortStringForSearch(normalized)

    return sortVal ? { ...withoutSort, s: sortVal } : withoutSort;
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  SAVE_TO_WINDOW_STORE({ search }) {
    TabStorage.set(TAB_STORAGE_KEY, search || {})
  },

  LOAD_FROM_WINDOW_STORE(state) {
    const storedSearch = TabStorage.fetch(TAB_STORAGE_KEY)

    if(storedSearch) state.search = storedSearch
  },

  PARSE(state) {
    const parsed = qs.parse(
      window.location.search,
      {
        ignoreQueryPrefix: true,
        skipNulls: true
      }
    );

    // Coerce Strings to Numbers
    // This makes things much cleaner when we compare these IDs to the IDs we get from the API JSON.
    const query = (parsed['q'] || {});
    const user_id_in = (query.user_id_in || []).map(Number);
    const story_sections_section_id_in = (query.story_sections_section_id_in || []).map(Number);
    const story_publications_publication_id_in = (query.story_publications_publication_id_in || []).map(Number);
    const squeue_id_in = (query.squeue_id_in || []).map(Number);

    const { s, ...withoutSort } = query;
    const [sort_column, sort_direction] = toString(s).split(' ');
    const sort = sort_column && sort_direction ? { sort_column, sort_direction } : {};

    state.search = Object.assign(
      {},
      DEFAULT_SEARCH,
      withoutSort,
      {
        user_id_in,
        story_sections_section_id_in,
        story_publications_publication_id_in,
        squeue_id_in
      },
      sort
    );
  },

  RESET(state) { Object.assign(state, { search: DEFAULT_SEARCH }) }
}

// actions
const actions = {
  ...make.actions(state),

  parseOrLoad({ commit }) {
    const parsed = qs.parse(
      window.location.search,
      {
        ignoreQueryPrefix: true,
        skipNulls: true
      }
    );

    if (parsed['q']) {
      commit('PARSE')
    } else {
      commit('LOAD_FROM_WINDOW_STORE')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
