<template lang="pug">
.locked-overlay
  div(:class="cssClasses")
    .panel.panel-warning(v-if="wasUpdated" )
      .panel-heading
        h3
          i.fa.fa-lock
          |
          | This story has been updated

      .panel-body
        p
          | This story has changed while you were away.
          br
          | If you choose to edit it anyway, the
          |
          strong recent changes may be lost.

        p
          button.btn.btn-default.btn-lg(type="button" @click="viewChanges")
            i.fa.fa-eye
            |
            | View Changes
          |
          |
          button.btn.btn-info.btn-lg(type="button" @click="refresh")
            i.fa.fa-refresh
            |
            | Refresh
          |
          |
          button.btn.btn-warning.btn-lg(type="button" @click="editAnyway")
            i.fa.fa-pencil
            |
            | Edit Anyway
    .panel.panel-danger(v-if="lockedToUser")
      .panel-heading
        h3
          i.fa.fa-lock
          |
          | This story is locked

      .panel-body
        p
          | This story was locked by {{ locker.name }}. They may have unsaved changes not reflected here.
          | If you choose to unlock it, they will immediately be locked out, and may lose their unsaved changes.

        p
          button.btn.btn-danger.btn-lg(type="button" @click="forceLock")
            i.fa.fa-unlock
            |
            | Unlock
  slot
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sync } from 'vuex-pathify'

const channel = 'StoryChannel'
const lastUpdatedByNullObject = () => {
  return {
    id: null,
    name: null
  }
}

export default {
  props: {
    storyId: {
      typeype: Number,
      required: true
    }
  },

  data() {
    return {
      lastUpdatedAt: null,
      lastUpdatedBy: lastUpdatedByNullObject()
    }
  },

  computed: {
    ...mapState(
      'story', [ 'locker', 'updated_at' ]
    ),

    ...mapGetters(
      'story', [ 'lockedToUser' ]
    ),

    currentUser: sync('currentUser/currentUser'),

    status() {
      if(this.lockedToUser) {
        return 'locked'
      } else if(this.lastUpdatedBy.id && this.lastUpdatedBy.id !== this.currentUser.id) {
        return 'updated'
      } else {
        return 'unlocked'
      }
    },

    wasUpdated() {
      return this.status === 'updated'
    },

    cssClasses() {
      return {
        "show-locked-overlay": (this.status !== 'unlocked')
      }
    }
  },

  channels: {
    [channel]: {
      async received(data) {
        const { action } = data

        switch(action) {
          case 'lock':
            const { locker } = data

            // If it's no longer locked and and no updates have been made, and we're sitting on the edit page,
            // we can now take over the lock and be allowed to edit it.
            if(!locker?.id && !this.wasUpdated) {
              await this.$store.dispatch('story/overrideLock')
            }

            this.$store.commit('story/SET_LOCKER', locker)
            break;
          case 'update':
            const { story: { updated_at }, user: { id, name } } = data

            this.lastUpdatedAt = updated_at
            this.lastUpdatedBy = { id, name }
            break;
        }
      }
    }
  },

  mounted() {
    this.$cable.subscribe({
      channel: channel,
      id: this.storyId,
      action: 'edit'
    })
  },

  methods: {
    viewChanges() {
      window.open(`/stories/${this.storyId}`, '_blank')
    },

    async refresh() {
      await this.$store.dispatch('story/fetch', { id: this.storyId})

      this.$store.dispatch('story/fetchDependentData')
      this.editAnyway()
    },

    editAnyway() {
      this.clearLastUpdated()
      this.forceLock()
    },

    forceLock() {
      this.$store.dispatch('story/overrideLock')
    },

    clearLastUpdated() {
      this.lastUpdatedAt = null
      this.lastUpdatedBy = lastUpdatedByNullObject()
    }
  }
}
</script>

<style lang="scss" scoped>
.locked-overlay {
  position: relative;

  .show-locked-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .panel {
    margin-top: 10em;

    .panel-body {
      padding: 4em 6em;
      max-width: 50em;
      text-align: center;
    }
  }
}
</style>
