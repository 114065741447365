import Vue from 'vue'

import Loader from './loader.vue';
Vue.component('loader', Loader);

import Pagination from './pagination.vue';
Vue.component('pagination', Pagination);

import PaginationDetails from './pagination_details.vue';
Vue.component('pagination-details', PaginationDetails);

import PublicationCheckboxes from './publication_checkboxes.vue';
Vue.component('publication-checkboxes', PublicationCheckboxes);

import SectionCheckboxes from './section_checkboxes.vue';
Vue.component('section-checkboxes', SectionCheckboxes);

import SqueueCheckboxes from './squeue_checkboxes.vue';
Vue.component('squeue-checkboxes', SqueueCheckboxes);

import UserCheckboxes from './user_checkboxes.vue';
Vue.component('user-checkboxes', UserCheckboxes);
