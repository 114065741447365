<template lang="pug">
div
  template(v-if="!edit")
    table.table.table-striped
      thead
        tr
          th Title
          th Slug
          th Notes
          th Stories
      tbody
        tr(v-for="page in pages")
          td
            router-link(:to="`/issues/${issueId}/pages/${page.id}`")
              | {{ page.title }}
          td {{ page.slug }}
          td {{ page.notes }}
          td {{ page.story_count }}

    .row
      .col-md-12.text-right
        button.btn.btn-default(v-on:click="edit = true")
          i.fa.fa-pencil
          |
          | Edit Pages

  template(v-if="edit")
    issue-pages-form(:issue-id="issueId")
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'

  export default {
    props: {
      issueId: {
        type: Number,
        required: true
      }
    },

    computed: {
    ...mapGetters('pages', { issuePages: 'forIssue' }),
    pages() { return this.issuePages({ id: this.issueId }) },
    edit: sync('pages/edit')
    },

    created() {
      this.$store.dispatch('pages/fetch', { issueId: this.issueId })
    }
  }
</script>
