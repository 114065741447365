import './admin';
import './ap';
import './files';
import './issues';
import './messages';
import './shared';
import './story_saved_searches';

import Vue from 'vue';

import DropdownCheckboxes from './dropdown_checkboxes.vue';
Vue.component('dropdown-checkboxes', DropdownCheckboxes);

import FormWithCsrfToken from './form_with_csrf_token.vue';
Vue.component('form-with-csrf-token', FormWithCsrfToken);

import StorySelect from './story_select.vue';
Vue.component('story-select', StorySelect);
