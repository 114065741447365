import axios from 'axios';
import { make } from 'vuex-pathify';

// initial state
const state = {
  sections: [],
  fetchInitiated: false
};

// getters
const getters = {
  ...make.getters(state),

  all({ sections }) {
    return sections;
  },

  enabled({ sections }) {
    return sections.filter(section => section.enabled);
  },

  enabledAsObject({ sections }) {
    let obj = {};
    sections.filter(section => {
      return section.enabled
    }).forEach(section => {
      obj[section.title] = section.id;
    });
    return obj;
  },

  forIds: ({ sections }) => (ids) => {
    return sections.filter(section => ids.includes(section.id));
  }
}

// mutations
const mutations = make.mutations(state);

// actions
const actions = {
  fetch({ commit, dispatch }) {
    axios.get('/api/internal/sections')
      .then(response => {
        commit('SET_SECTIONS', response.data.sections);
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true});
      });

    commit('SET_FETCH_INITIATED', true);
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return;

    dispatch('fetch');
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
