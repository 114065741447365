<template lang="pug">
form
  input(type="hidden" :name="csrfParam" :value="csrfToken")

  slot
</template>

<script>
// This handles attaching a Rails CSRF token to a form generated in Vue
export default {
  computed: {
    csrfToken() {
      return this.getMetaTagContent('csrf-token');
    },

    csrfParam() {
      return this.getMetaTagContent('csrf-param');
    }
  },

  methods: {
    getMetaTagContent(tagName) {
      return document.getElementsByName(tagName)[0].getAttribute('content');
    }
  }
}
</script>
