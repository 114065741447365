import axios from 'axios'
import { make } from 'vuex-pathify'

const STORY_SOURCE_TYPE = 'Story'

// initial state
const state = {
  exportFormats: [],
  fetchInitiated: false,
}

// getters
const getters = {
  ...make.getters(state),

  all({ exportFormats }) {
    return exportFormats
  },

  forStories({ exportFormats }) {
    return exportFormats.filter(({ source_type }) => source_type == STORY_SOURCE_TYPE);
  },

  allAsObject({ exportFormats }) {
    let obj = {}
    exportFormats.forEach(exportFormat => {
      obj[exportFormat.name] = exportFormat.id
    })
    return obj
  },

  allAutoExports({ exportFormats }) {
    return exportFormats.filter(format => format.auto_export);
  },

  forIds: ({ exportFormats }) => (ids) => {
    return exportFormats.filter(exportFormat => ids.includes(exportFormat.id));
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  fetch({ commit, dispatch }) {
    axios.get('/api/internal/export_formats')
      .then(response => {
        commit('SET_EXPORT_FORMATS', response.data.export_formats)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {
          root: true
        })
      })

    commit('SET_FETCH_INITIATED', true)
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return;

    dispatch('fetch');
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
