// This is the default string format we use for dates throughout the app
const FORMATTED_DATE_REGEX_MATCH = /^(\d{4})-(\d{2})-(\d{2})$/
const DATE_STRING_OPTIONS = { year: 'numeric', month: '2-digit', day: '2-digit' }

const timeToEndOfDay = ((time) => {
  if (!time) return null

  let date = new Date(time)
  date.setHours(23, 59, 59, 999)

  return date
})

const toDateInLocalTZ = (val) => {
  // Values we can't, or don't need to parse
  if (!val || val instanceof Date) return val

  // If the value is a string in the format of YYYY-MM-DD, build a new Date object
  // rather than parsing so that the date is in the local timezone, rather than UTC.
  if(typeof val === 'string' && val.match(FORMATTED_DATE_REGEX_MATCH)) {
    const [, year, month, day] = val.match(FORMATTED_DATE_REGEX_MATCH).map(Number)

    return new Date(year, month - 1, day)
  }

  // Otherwise, parse the value as a date
  return new Date(val)
}

// Returns Date objects in the format of YYYY-MM-DD
const formatDate = (date) => {
  if (!date) return null

  return date.toLocaleDateString(undefined, DATE_STRING_OPTIONS).replace(/\//g, '-')
}

export { timeToEndOfDay, toDateInLocalTZ, formatDate }
