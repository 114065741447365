<template lang="pug">
.form-group
  label.control-label Web categories
  div
    dropdown-checkboxes(label="Web categories" v-model="webCategoryIds" :options="$store.getters['webCategories/allAsObject']" class="block")
</template>

<script>
  export default {
    props: {
      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    data() {
      return {
        webCategoryIds: []
      }
    },

    watch: {
      value() {
        this.webCategoryIds = this.value
      },

      webCategoryIds() {
        // For component v-model support
        this.$emit('input', this.webCategoryIds);
      }
    },

    async mounted() {
      await this.$store.dispatch('webCategories/fetch')
    }
  }
</script>
