import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  onlinestatuses: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ onlinestatuses }) {
    return onlinestatuses
  },

  forId: ({ onlinestatuses }) => (id) => {
    return onlinestatuses.find(onlinestatus => onlinestatus.id === id);
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  fetch({ commit, dispatch }) {
    axios.get('/api/internal/onlinestatuses')
      .then(response => {
        commit('SET_ONLINESTATUSES', response.data.onlinestatuses)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })

    commit('SET_FETCH_INITIATED', true)
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return;

    dispatch('fetch');
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
