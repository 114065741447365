/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add javascript_pack_tag 'application' to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Imports _____________________________________________________________________
import 'bootstrap';
import '../src/application.scss';

import Vue from 'vue';
import axios from 'axios';
import { eventHub } from '../utils/event_hub';
import store from '../store';
import router from '../router';

import '../components';
import '../filters';
import '../pages';

require('@rails/ujs').start();

import "font-awesome/scss/font-awesome.scss";

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

  // Error Reporting _____________________________________________________________
const sentryDsn = "https://6a15cff355ff4610b02cafed70df03e1@sentry.io/201774";
if(sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  });
}

// Application _________________________________________________________________
new Vue({
  el: '#newsroomq-app',

  store,
  router,

  data: function() {
    return {
      // This is here as an easy way to hold whatever properties you may may want
      // to assign to and read from it
      dynamic: {}
    };
  },

  mounted: function() {
    // NOTE: add a <script type="application/json" id="vue-newsroomq-app-data">
    // tag to load JSON data into the $data.dynamic object.
    var jsonScriptTag = document.getElementById('vue-newsroomq-app-data');

    if(jsonScriptTag) {
      var jsonData = JSON.parse(jsonScriptTag.innerHTML);
      Vue.set(this.$data, 'dynamic', jsonData);
    }

    // CSRF support for Axios //
    let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['Accept'] = 'application/json';

    let form = this.$el.querySelector('form');
    // Assume we only want ctrl+s to work for POSTs that change data, not GETs
    // that just read data (like search forms)
    if(form && form.method == 'post') {
      document.addEventListener(
        'keyup',
        function(e) {
          const sKeyCode = 83; // "s" key

          if(e.ctrlKey && e.keyCode == sKeyCode) { form.submit() }
        }
      );
    }
  },

  methods: {
    emitToHub: function (eventName, eventData) {
      eventHub.$emit(eventName, eventData);
    }
  }
})
