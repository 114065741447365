<template lang="pug">
.form-group
  div(v-if="show")
    label.control-label Subsites
    div
      dropdown-checkboxes(
        label="Subsites"
        :value="value"
        @input="emitInput"
        :options="subsiteOptions"
        class="block"
      )
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  props: {
    publicationId: {
      type: Number,
      required: true,
    },

    // Array of values from options
    // NOTE: be careful that the values match the options values. Number strings are a common issue.
    value: {
      type: Array,
      default() { return [] }
    }
  },

  computed: {
    ...mapGetters('subsites', { subsitesForPub: 'forPublicationAsObject' }),

    subsiteOptions() {
      return this.subsitesForPub(this.publicationId)
    },

    show() {
      return !isEmpty(this.subsiteOptions)
    }
  },

  async mounted() {
    await this.$store.dispatch('subsites/fetchOnce')
  },

  methods: {
    emitInput(subsiteTownIds) {
      // For component v-model support
      this.$emit('input', subsiteTownIds);
    }
  }
}
</script>
