import axios from 'axios';
import { make } from 'vuex-pathify';

// initial state
const state = {
  users: [],
  fetchInitiated: false
};

// getters
const getters = {
  ...make.getters(state),

  all({ users }) {
    return users;
  },

  allAsObject({ users }) {
    let obj = {};
    users.forEach(user => { obj[user.name] = user.id });
    return obj;
  },

  forId: ({ users }) => (id) => {
    return users.find(user => user.id === id);
  }
}

// mutations
const mutations = make.mutations(state);

// actions
const actions = {
  fetch({ commit, dispatch }) {
    axios.get('/api/internal/users')
      .then(response => {
        commit('SET_USERS', response.data.users);
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true });
      });

    commit('SET_FETCH_INITIATED', true);
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return;

    dispatch('fetch');
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
