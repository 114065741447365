import Vue from 'vue'
import { eventHub } from '../../utils/event_hub'

Vue.component('ap-routing-dialog', {
  template: '#ap-routing-dialog-template',

  data() {
    return {apStoryId: null};
  },

  created() {
    eventHub.$on('show-ap-routing-dialog', this.show);
  },

  beforeDestroy() {
    eventHub.$off('show-ap-routing-dialog', this.show);
  },

  methods: {
    show(eventData) {
      $(this.$el).modal({show: true});
      this.apStoryId = eventData.apStoryId;
    }
  }
}
);
