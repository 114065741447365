import axios from 'axios';
import { make } from 'vuex-pathify';

const getDefaultState = () => {
  return {
    storyPlacements: []
  }
}

// initial state
const state = getDefaultState();

// helper methods
const includesBlank = ({ storyPlacements }) => {
  return storyPlacements.map(pl => pl.id).includes(null)
}

// getters
const getters = {
  ...make.getters(state),

  withIdKeys({ storyPlacements }) {
    let obj = {};
    storyPlacements.forEach(place => { obj[place.id] = place })
    return obj
  },

  includesBlank
}

// mutations
const mutations = {
  ...make.mutations(state),

  UPDATE_STORY_PLACEMENT(state, placement) {
    state.storyPlacements = state.storyPlacements.map(place => {
      return place.id === placement.id ? placement : place
    })
  },

  ADD({ storyPlacements }, placement) {
    storyPlacements.push(placement)
  },

  ADD_BLANK({ storyPlacements }, { storyId }) {
    // Only allow one at a time. Ideally, we'd allow adding multiple blanks but
    // it causes problems with unique keys, so I'm gonna leave it like this
    // until we have time to refactor a bit.
    if(includesBlank({ storyPlacements })) return

    storyPlacements.push({
      id: null,
      story_id: storyId,
      issue_id: null,
      page_id: null
    })
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
};

// actions
const actions = {
  ...make.actions(state),

  fetch({ commit, dispatch }, { storyId }) {
    return axios
      .get(`/api/internal/stories/${storyId}/story_placements`)
      .then(({ data }) => {
        commit('SET_STORY_PLACEMENTS', data.story_placements);
      })
      .catch(err => {
        console.error(err);
        dispatch('messages/addError', err, { root: true });
      });
  },

  addIssue({ commit }, { issueId }) {
    commit('ADD', { issue_id: Number(issueId) } )
  },

  addPage({ commit }, { issueId, pageId }) {
    commit('ADD', { issue_id: Number(issueId), page_id: Number(pageId) })
  },

  async updateStoryPlacement({ state: { storyPlacements }, commit, dispatch }, placement) {
    commit('UPDATE_STORY_PLACEMENT', placement)

    // If there are multiple placements, use the first one for the publish_on date
    if (placement.id !== storyPlacements[0]?.id) return

    const issueId = placement.issue_id
    dispatch('story/autoSetPubDateFromIssue', { issueId }, { root: true })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
