import axios from 'axios';
import { make } from 'vuex-pathify';

// initial state
const state = {
  storyTemplates: [],
  fetchInitiatedPublications: []
};

// getters
const getters = {
  ...make.getters(state),

  fetchInitiatedFor: ({ fetchInitiatedPublications }) => ({ publicationId }) => {
    return fetchInitiatedPublications.includes(Number(publicationId))
  },

  byId: ({ storyTemplates }) => (story_template_id) => {
    return storyTemplates.find(({ id }) => id == story_template_id)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),
  ADD_PUBLICATION_TO_FETCH_INITIATED({ fetchInitiatedPublications }, { publicationId }) {
    const id = Number(publicationId)

    // Avoid duplicates
    if (fetchInitiatedPublications.includes(id)) return

    fetchInitiatedPublications.push(id)
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetch({ commit, dispatch }, publicationId) {
    commit('ADD_PUBLICATION_TO_FETCH_INITIATED', { publicationId })

    return axios.get(`/api/internal/publications/${publicationId}/community_q/story_templates`)
      .then(response => {
        dispatch('setStoryTemplates', response.data.story_templates);
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true });
      });
  },

  async fetchOnce({ dispatch, getters }, publicationId) {
    if (getters['fetchInitiatedFor']({ publicationId })) return Promise.resolve()

    return dispatch('fetch', publicationId)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
