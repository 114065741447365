import Vue from 'vue'
import axios from 'axios'
import { make } from 'vuex-pathify'
import { isEmpty, pickBy } from 'lodash'
import { withIDKeys } from '../../utils/state_helpers.js'
import { exifMediumContent } from '../../utils/exif_helpers.js'

// helpers
function newObject({ storyMedia }) {
  const maxListOrder = Math.max(...Object.values(storyMedia).map(sM => sM.list_order), 0);

  return Object.assign({}, {
    key: null,
    id: null,
    use_as_content_for: null,
    for_print: true,
    for_web: true,
    list_order: (maxListOrder + 1),
    _destroy: false,
    medium: {
      cutline: '',
      credit: '',
      url: null,
      small_thumb_url: null,
      large_thumb_url: null,
      file: null,
      upload_filename: null,
      filename: null,
      content_type: null
    }
  })
}

const getDefaultState = () => {
  return {
    storyId: null,
    storyMedia: {}
  }
}

// initial state
const state = getDefaultState()
// Passed to Vuex Pathify so it knows what the eventual state will look like once records are loaded.
const schemaState = Object.assign(
  {},
  getDefaultState(),
  { storyMedia: { 0: newObject(state) } }
)

// getters
const getters = {
  ...make.getters(schemaState),

  // Attachments are StoryMedia that are not used for content so they have `use_as_content_for: nil`
  attachments: ({ storyMedia }) => pickBy(storyMedia, sm => !sm.use_as_content_for),
  noAttachments: (_state, getters) => isEmpty(getters.attachments),

  all: ({ storyMedia }) => { return storyMedia },
  // An array of story media that is not used fir content fields.
  attachments: ({ storyMedia }) => {
    return Object.values(storyMedia)
      .filter(sm => !sm.use_as_content_for)
      .sort((a, b) => (a.list_order > b.list_order) ? 1 : -1)
  },

  paths: ({ storyMedia }) => {
    let paths = []
    for(let key in storyMedia) {
      if(storyMedia[key].medium) {
        paths.push(storyMedia[key].medium.relative_path);
      }
    }
    return paths;
  },

  byContentFor: ({ storyMedia }) => (contentFor) => {
    for(let key in storyMedia) {
      if(storyMedia[key].use_as_content_for == contentFor) {
        return storyMedia[key];
      }
    }
  }
}

// mutations
const mutations = {
  ...make.mutations(schemaState),

  UPDATE({ storyMedia }, { id, storyMedium }) {
    storyMedia[id] = storyMedium
  },

  UPDATE_LIST_ORDER({ storyMedia }, newOrder) {
    newOrder.forEach(({ id, list_order }) => {
      if(storyMedia[id]) storyMedia[id].list_order = list_order
    })
  },

  APPEND({ storyMedia }, storyMedium) {
    Vue.set(storyMedia, storyMedium.id, storyMedium)
  },

  REMOVE({ storyMedia }, storyMediumId) {
    Vue.delete(storyMedia, storyMediumId)
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(schemaState),

  fetchForStory({ dispatch, commit }, storyId) {
    commit('SET_STORY_ID', storyId)

    axios.get(`/api/internal/stories/${storyId}/story_media`)
      .then(response => {
        dispatch('setStoryMedia', withIDKeys(response.data.story_media));
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true});
      })
  },

  create({ commit, dispatch, state: { storyId } }, { file, useAsContentFor }) {
    return new Promise((resolve, reject) => {
      exifMediumContent(file).then(({ cutline, credit}) => {
        let formData = new FormData();

        if(useAsContentFor) formData.append(`story_medium[use_as_content_for]`, useAsContentFor)
        formData.append(`story_medium[medium_attributes][upload_filename]`, file.name)
        formData.append(`story_medium[medium_attributes][upload]`, file)
        if(cutline) formData.append(`story_medium[medium_attributes][cutline]`, cutline)
        if(credit) formData.append(`story_medium[medium_attributes][credit]`, credit)

        axios.post(`/api/internal/stories/${storyId}/story_media`, formData)
        .then(({ data: { story_medium } }) => {
          commit('APPEND', story_medium)
          resolve(story_medium)
        })
        .catch((err) => {
          dispatch('messages/smartAdd', err, { root: true })
          reject(err)
        })
      })
    })
  },

  batchCreate({}, { storyIds, mediumIds }) {
    return axios.post('/api/internal/story_media/batch_create', {
      story_ids: storyIds,
      media_ids: mediumIds
    })
  },

  createFromUnrecognized({ commit, dispatch, state: { storyId } }, { path }) {
    axios.post(
      `/api/internal/stories/${storyId}/story_media`,
      { story_medium: { medium_attributes: { file: path } } }
    ).then(({ data: { story_medium } }) => {
      commit('APPEND', story_medium)
    })
    .catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  createFromMedium({ commit, dispatch, state: { storyId }}, { mediumId }) {
    return axios.post(
      `/api/internal/stories/${storyId}/story_media`,
      { story_medium: { medium_id: mediumId } }
    ).then(({ data: { story_medium } }) => {
      commit('APPEND', story_medium)
    })
    .catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  save({ commit, dispatch, state: { storyId, storyMedia }}) {
    // Return a no-op promise
    if (storyMedia.length === 0) return new Promise((res) => { res() })

    return axios.patch(
      `/api/internal/stories/${storyId}/story_media/batch_update`,
      {
        story_media: withIDKeys(storyMedia)
      }
    ).then(({ data: { story_media } }) => {
      commit('SET_STORY_MEDIA', withIDKeys(story_media))
      dispatch('messages/addNotice', 'Attachments saved', { root: true })
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  destroy({ commit, dispatch, state: { storyId } }, { storyMediumId }) {
    axios.delete(`/api/internal/stories/${storyId}/story_media/${storyMediumId}`)
      .then(({ data: { message } }) => {
        commit('REMOVE', storyMediumId)
        dispatch('messages/addNotice', message, { root: true })
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
