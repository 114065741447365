<template lang="pug">
.form-group
  label.control-label Print Sections
  div
    dropdown-checkboxes(label="Print Sections" v-model="sectionIds" :options="$store.getters['sections/enabledAsObject']" class="block")
</template>

<script>
  export default {
    props: {
      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    data() {
      return {
        sectionIds: []
      }
    },

    watch: {
      value() {
        this.sectionIds = this.value
      },

      sectionIds() {
        // For component v-model support
        this.$emit('input', this.sectionIds);
      }
    },

    async mounted() {
      await this.$store.dispatch('sections/fetch')
    }
  }
</script>
