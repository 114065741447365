import axios from 'axios'
import { make } from 'vuex-pathify'
import { xor } from 'lodash'
import { exifMediumContent } from '../../utils/exif_helpers.js'
import { getDefaultPaginationState } from '../shared/pagination.js'

// initial state
const state = {
  ...getDefaultPaginationState(),
  media: [],
  selectedMediaIds: [],
  loading: false
}

// getters
const getters = {
  ...make.getters(state),

  // Returns the selected media only if there is only one media selected
  soleSelected({ media, selectedMediaIds }) {
    if(selectedMediaIds.length !== 1) return null

    return media.find((medium) => medium.id === selectedMediaIds[0])
  },

  selected({ media, selectedMediaIds }) {
    return media.filter((medium) => selectedMediaIds.includes(medium.id) )
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  TOGGLE(state, id) {
    state.selectedMediaIds = xor(state.selectedMediaIds, [id])
  },

  CLEAR_SELECTED(state) {
    state.selectedMediaIds = []
  },

  SET_PAGE_CURRENT(state, page) {
    state.page.current = page
  },

  PREPEND({ media }, medium) {
    media.unshift(medium) // Since it's the newest, it goes at the beginning
    media.pop() // Remove the last medium, so we keep the same page length
  },
}

// actions
const actions = {
  ...make.actions(state),

  search({ getters, commit, dispatch, rootGetters }, options) {
    if(options?.page) commit('SET_PAGE_CURRENT', options.page)
    const page = getters['page'].current

    commit('SET_LOADING', true)
    return axios.get('/api/internal/media', {
      params: {
        ...rootGetters['mediaSearch/forQueryParams'],
        page
      }
    }).then(({ data: { meta, media } }) => {
      dispatch('clearSelected')
      dispatch('setMedia', media)
      dispatch('setPage', meta.page)
      commit('SET_LOADING', false)
    })
  },

  toggle({ commit }, id) {
    commit('TOGGLE', id)
  },

  clearSelected({ commit, dispatch }) {
    dispatch('medium/reset', null, { root: true })
    commit('CLEAR_SELECTED')
  },

  create({ commit, dispatch }, { file }) {
    return new Promise((resolve, reject) => {
      exifMediumContent(file).then(({ cutline, credit }) => {
        let formData = new FormData()

        formData.append(`medium[upload]`, file)
        if (cutline) formData.append(`medium[cutline]`, cutline)
        if (credit) formData.append(`medium[credit]`, credit)

        axios.post('/api/internal/media', formData)
          .then(({ data: { medium } }) => {
            commit('PREPEND', medium)
            resolve(medium)
          })
          .catch((err) => {
            dispatch('messages/smartAdd', err, { root: true })
            reject(err)
          })
      })
    })
  },

  destroy({ commit, dispatch }, { ids }) {
    commit('SET_LOADING', true)

    return axios.delete(
      '/api/internal/media/batch_destroy',
      { params: { ids } }
    ).then(() => {
      dispatch('clearSelected')
      dispatch('search') // search will set loading to false for us
    }).catch(err => {
      commit('SET_LOADING', false)
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
