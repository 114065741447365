<template lang="pug">
.messages
  Message(v-for="({ type, text }, id) in allMessages" :type="type" :text="text" :key="id")
</template>

<script>
  import Message from './message.vue'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Message
    },

    props: {
      messages: {
        type: Array,
        required: true
      }
    },

    computed: mapGetters('messages', { allMessages: 'messages' }),

    mounted() {
      this.messages.forEach(message => {
        this.$store.commit('messages/ADD', message);
      })
    }
  }
</script>

<style lang="scss" scoped>
  .messages {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 20px;

    :not(:last-child) {
      display: none;
    }
  }
</style>
