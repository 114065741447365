import { isEmpty } from 'lodash'
import TabStorage from '../../utils/tab_storage.js';

const NON_SCORE_STORAGE_KEY = 'last-non-score-sort'
const SCORE_SORT = '_score'
const DEFAULT_SORT_COLUMN = 'pub_date'
const DEFAULT_SORT_DIRECTION = 'desc'

const sortParamsForSearch = ({ text_cont, sort_column, sort_direction }) => {
  // Whenever we get a search that is trying to sort by relevance scoere, but can't because there is no fulltext search,
  // we want to fall back on the last non-score sort that was used. So we store it here for later use.
  if(sort_column !== SCORE_SORT) {
    TabStorage.set(NON_SCORE_STORAGE_KEY, { sort_column, sort_direction })
  }

  // If they're trynig to sort by fulltext relevance match socore, but there is no fulltext search...
  if(isEmpty(text_cont) && sort_column === SCORE_SORT) {
    // Pull the last non-score sort from storage.
    return TabStorage.fetch(NON_SCORE_STORAGE_KEY) || {}
  }

  // Otherwise, just fall back on the default sort.
  return { sort_column, sort_direction }
}

const sortStringForSearch = (search) => {
  const { sort_column, sort_direction } = sortParamsForSearch(search)

  const col = sort_column || DEFAULT_SORT_COLUMN
  const dir = sort_direction || DEFAULT_SORT_DIRECTION

  return `${col} ${dir}`.trim()
}

export {
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  sortParamsForSearch,
  sortStringForSearch
}

