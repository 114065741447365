<template lang="pug">
dropdown-checkboxes(
  :label="label"
  :name="name"
  :options="publicationOptions"
  :value="value"
  @input="update"
  :loading="loading"
  v-if="multipleOptions"
)
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default() { return 'Publication' }
      },

      name: {
        type: String,
        default() { return 'q[story_publications_publication_id_in][]' }
      },

      value: {
        type: Array,
        default() { return [] }
      }
    },

    computed: {
      publicationOptions() {
        return this.$store.getters['publications/allAsObject'];
      },

      multipleOptions() {
        return this.$store.getters['publications/multiple'];
      },

      loading() {
        return this.$store.getters['publications/all'].length === 0;
      }
    },

    mounted() {
      this.$store.dispatch('publications/fetch');
    },

    methods: {
      update(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
</script>
