<template lang="pug">
.input-group
  DatePicker(
    :name="name"
    v-model="pickedDate"
    ref="picker"
    input-class="form-control"
    format="yyyy-MM-dd"
    :disabled-dates="disabledDates"
    :placeholder="placeholder"
  )
  span.input-group-btn
    template(v-if="pickedDate")
      button.btn.btn-default(type="button" v-on:click="clearDate()")
        i.fa.fa-times
    template(v-if="!pickedDate")
      button.btn.btn-default(type="button" v-on:click="showCalendar()")
        i.fa.fa-calendar
</template>

<script>
  import { toDateInLocalTZ } from '../../utils/time_helpers'

  import DatePicker from '@sum.cumo/vue-datepicker';

  export default {
    components: {
      DatePicker
    },

    props: {
      value: {
        default: null
      },
      name: {
        type: String,
        default: 'date'
      },
      disabledDates: {
        type: Object,
        default: () => ({})
      },
      placeholder: {
        type: String,
        defalut: 'Date'
      }
    },

    computed: {
      pickedDate: {
        get() { return toDateInLocalTZ(this.value) },
        set(val) { this.emit(val); }
      }
    },

    methods: {
      showCalendar() {
        this.$refs.picker.showCalendar();
      },

      clearDate() {
        this.emit(null);
      },

      emit(val) {
        this.$emit('input', val);
      }
    }
  }
</script>

<style src="@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css"/>
