<template lang="pug">
compntent(:is="tag" type="button" @click="openDialog" v-if="authorized && configured")
  slot Google Drive
</template>

<script>
  /*
   * NOTE: you must enable the Google Picker API to use this component
   */

  import Vue from 'vue';
  import VueJsonp from 'vue-jsonp';
  import { sync } from 'vuex-pathify';
  import axios from 'axios';

  Vue.use(VueJsonp, 10000) // 10 sec timeout

  const developerKey = ''
  const clientId = ''
  const appId = ''

  const scope = ['https://www.googleapis.com/auth/drive']

  export default {
    props: {
      tag: {
        type: String,
        default() { return 'button' }
      },
      mimeTypes: {
        type: Array,
        default() { return ['image/png', 'image/jpeg', 'image/jpg'] }
      },
      value: {
        type: File,
        default() { return null }
      }
    },

    data() {
      return {
        file: null,
        fileBase64Url: null,
        fileame: null,
        fileContentType: null,
        authApiLoaded: false,
        clientApiLoaded: false,
        driveApiLoaded: false,
        pickerApiLoaded: false,
        oauthToken: null
      }
    },

    computed: {
      currentUser: sync('currentUser/currentUser'),
      authenticated() { return !!this.oauthToken },
      configured() { return !!(developerKey && clientId && appId) },
      authorized() { return this.currentUser.can_access_google_picker }
    },

    watch: {
      authorized(auth) {
        if(auth) { this.initGoogleApis() }
      }
    },

    mounted() {
      this.$store.dispatch('currentUser/fetch')
    },

    methods: {
      openDialog() {
        if(this.authenticated) {
          this.showDialog()
        } else {
          this.authenticate()
        }
      },

      initGoogleApis() {
        this.$jsonp('https://apis.google.com/js/api.js', {callbackQuery: 'onload'}).then(() => {
          gapi.load('auth', {callback: () => { this.authApiLoaded = true }})
          gapi.load('picker', {callback: () => { this.pickerApiLoaded = true }})
          gapi.load('client', {callback: () => {
            this.clientApiLoaded = true
            gapi.client.load('drive', 'v3', () => { this.driveApiLoaded = true })
          }})
        }).catch(err => {
          this.$store.dispatch('messages/addAlert', err.statusText)
        })
      },

      authenticate() {
        if(this.authApiLoaded) {
          gapi.auth.authorize(
            { client_id: clientId, scope: scope, immediate: false },
            (result) => {
              if(!result) {
                this.showAlert('Authorization failed. Please try again.')
              } else if (result.error) {
                this.showAlert(result.error)
              } else {
                // It may be nice to store the oauth token local storange so that the user doesn't
                // have to re-choose a Google account, but then it would be annoying if you wanted
                // to change accounts too. So we'll leave it as-is for now.
                this.oauthToken = result.access_token
                this.showDialog()
              }
            }
          )
        } else {
          this.apiNotLoadedError()
        }
      },

      showDialog() {
        if(this.pickerApiLoaded) {
          let view = new google.picker.View(google.picker.ViewId.DOCS)
          view.setMimeTypes(this.mimeTypes.join(','))

          let picker = new google.picker.PickerBuilder()
              .enableFeature(google.picker.Feature.NAV_HIDDEN)
              .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
              .setAppId(appId)
              .setOAuthToken(this.oauthToken)
              .addView(view)
              .addView(new google.picker.DocsUploadView())
              .setDeveloperKey(developerKey)
              .setCallback(this.handleFilePicked.bind(this))
              .build()
          picker.setVisible(true)
        } else {
          this.apiNotLoadedError()
        }
      },

      handleFilePicked(data) {
        if (this.clientApiLoaded && this.driveApiLoaded) {
          if (data.action == google.picker.Action.PICKED) {
            let selectedFile = data.docs[0]
            this.fileName = selectedFile.name
            this.fileContentType = selectedFile.mimeType

            this.$emit('download-started')

            if (selectedFile.type == 'document') {
              gapi.client.drive.files.export(
                { fileId: selectedFile.id, mimeType: 'application/pdf' }
              ).then(this.handleDriveResponse)
            } else {
              gapi.client.drive.files.get(
                { fileId: selectedFile.id, alt: 'media' }
              ).then(this.handleDriveResponse)
            }
          }
        } else {
          this.apiNotLoadedError()
        }
      },

      handleDriveResponse(resp) {
        this.fileBase64Url = `data:${this.fileContentType};base64,${btoa(resp.body)}`

        fetch(this.fileBase64Url)
          .then(resp => resp.blob())
          .then(blob => {
            this.file = new File([blob], this.fileName, {type: this.fileContentType})
            this.emitValue()
          })
      },

      emitValue() {
        // For component v-model support
        this.$emit('input', this.file)
      },

      apiNotLoadedError() {
        this.showAlert("Google isn't loaded yet. Please try again.")
      },

      showAlert(message) {
        this.$store.dispatch('messages/addAlert', message)
      }
    }
  }
</script>
