import axios from 'axios';

// initial state
const state = [];

// getters
const getters = {
  all(state) {
    return state;
  }
}

// mutations
const mutations = {
  set(state, bylinePresets) {
    // Replacing the array breaks change detection, so splice and push instead
    state.splice(0, state.length)
    state.push(...bylinePresets)
  }
}

// actions
const actions = {
  fetch({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/internal/community_q/byline_presets')
        .then(response => {
          commit('set', response.data.byline_presets);
          resolve();
        })
        .catch((err) => {
          dispatch('messages/smartAdd', err, {
            root: true
          });
          reject();
        })
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
