<template lang="pug">
div
  p
    story-select(v-model="selectedStoryId")
      button.btn.btn-default.btn-block(v-on:click="attach()" slot="footer")
        i.fa.fa-paperclip
        |
        | Attach to Story

  p
    .btn-group.btn-group-justified
      a.btn.btn-default(:href="downloadPath")
        i.fa.fa-cloud-download
        |
        | Download

      .btn-group
        button.btn.btn-danger(v-on:click="destroy")
          i.fa.fa-trash
          |
          | Delete
</template>

<script>
  import { sync } from 'vuex-pathify'

  export default {
    data() {
      return {selectedStoryId: null};
    },

    computed: {
      ...sync('media', [
        'selectedMediaIds'
      ]),

      downloadPath() {
        return `/media.zip?${jQuery.param({ids: this.selectedMediaIds})}`;
      }
    },

    methods: {
      attach() {
        const mediumIds = this.selectedMediaIds

        this.$store.dispatch(
          'storyMedia/batchCreate',
          { storyIds: [this.selectedStoryId], mediumIds }
        ).then(() => {
          this.selectedStoryId = null
          this.$store.dispatch('messages/addNotice', `Story attached to ${mediumIds.length} media`, { root: true })
          this.$store.dispatch('media/search')
        })
      },

      destroy() {
        const ids = this.selectedMediaIds
        if (confirm(`Sure you want to delete all ${ids.length} media?`)) {
          this.$store.dispatch('media/destroy', { ids }).then(() => {
            this.$store.dispatch('messages/addNotice', `${ids.length} stories destroyed`, { root: true })
          })
        }
      }
    }
  }
</script>
