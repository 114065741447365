import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  // All file data must start within a resource's storage path
  resourceGlobalId: null,
  currentPathParts: [],
  selectedFile: null,
  files: []
}

// getters
const getters = {
  ...make.getters(state),

  orderedFiles(state) {
    return state.files.sort((a, b) => {
      // Conveniently, the two types are "directory" and "file" which works
      // out alphabetically since we want the directories sorted first. And
      // since no directory should ever hold a duplicate file or directory
      // names, we should never have to check for the two being equal.
      return (`${a.type}-${a.name}` > `${b.type}-${b.name}` ? 1 : -1)
    })
  },

  currentPath(state) {
    return state.currentPathParts.join('/')
  },

  resourcePath(_state, getters) {
    const base64ResourceGlobalId = getters['base64ResourceGlobalId']
    if (!base64ResourceGlobalId) { throw 'A resourceGlobalID is required' }

    return `/api/internal/resources/${base64ResourceGlobalId}`
  },

  resourceFilesPath: (_state, getters) => (filePath) => {
    const path = filePath || getters['currentPath']

    return `${getters['resourcePath']}/files/${path}`
  },

  resourceFoldersPath: (_state, getters) => (folderPath) => {
    return `${getters['resourcePath']}/folders/${folderPath}`
  },

  base64ResourceGlobalId(state) {
    if(!state.resourceGlobalId) { return null }

    return btoa(state.resourceGlobalId)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET_CURRENT_PATH(state) {
    state.currentPathParts = []
  }
}

// actions
const actions = {
  ...make.actions(state),

  goBack({ state, commit, dispatch }) {
    let newParts = [...state.currentPathParts]
    newParts.pop()

    commit('SET_CURRENT_PATH_PARTS', newParts)
    dispatch('fetch')
  },

  goDownTo({ state, commit, dispatch }, directoryName) {
    let newParts = [...state.currentPathParts]
    newParts.push(directoryName)

    commit('SET_CURRENT_PATH_PARTS', newParts)
    dispatch('fetch')
  },

  goTo({ commit, dispatch }, newPathParts) {
    commit('SET_CURRENT_PATH_PARTS', newPathParts)
    dispatch('fetch')
  },

  fetch({ getters, commit, dispatch }, resourceGlobalId) {
    if(resourceGlobalId) { commit("SET_RESOURCE_GLOBAL_ID", resourceGlobalId) }

    const currentPath = getters['currentPath']
    const path = getters.resourceFilesPath(currentPath)

    axios
      .get(path)
      .then(response => {
        commit("SET_FILES", response.data.files)
      })
      .catch(err => {
        console.error(err)
        dispatch("messages/addError", err, { root: true })
      })
  },

  createFolder({ getters, commit, dispatch }, folderPath ) {
    const path = getters.resourceFoldersPath(folderPath)

    return axios
      .post(path)
      .then(response => {
        commit('SET_FILES', response.data.files)
      })
      .catch(err => {
        console.error(err)

        dispatch('messages/addError', err, { root: true })
      })
  },

  async uploadFile({ getters, commit, dispatch }, { filePath, file }) {
    let base64ResourceGlobalId = getters['base64ResourceGlobalId']
    if (!base64ResourceGlobalId) {
      throw 'A resourceGlobalID is required'
    }

    const path = getters.resourceFilesPath(filePath)

    let formData = new FormData()
    formData.append('file', file)

    return axios
      .post(path, formData)
      .then(response => {
        commit('SET_FILES', response.data.files)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  destroy({ getters, commit, dispatch }, filePath) {
    const path = getters.resourceFilesPath(filePath)

    axios
      .delete(path)
      .then(response => {
        commit("SET_FILES", response.data.files)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
