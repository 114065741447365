import axios from 'axios';
import { make } from 'vuex-pathify';

// initial state
const state = {
  wordsPerInch: 0,
  masterTemplate: false,
  hotQueues: false,
  perPageDefault: 0,
  fetchInitiated: false
};

// getters
const getters = make.getters(state);

// mutations
const mutations = make.mutations(state);

// actions
const actions = {
  ...make.actions(state),

  fetch({ commit, dispatch }) {
    axios.get('/api/internal/settings')
      .then(response => {
        const settings = response.data.settings;

        dispatch('setWordsPerInch', settings.words_per_inch);
        dispatch('setMasterTemplate', settings.master_template);
        dispatch('setHotQueues', settings.hot_queues);
        dispatch('setPerPageDefault', settings.per_page_default);
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true});
      });

    commit('SET_FETCH_INITIATED', true);
  },

  fetchOnce({ dispatch, state }) {
    if(state.fetchInitiated) return;

    dispatch('fetch');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
